import { withPrefix } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Button, Col, Container, Row } from 'react-bootstrap'
import paper from "../../files/Leadership-in-the-21st-century-an-oxymoron-May-2018.pdf"
import "./events.scss"

const Events = ({ className }) => {
    const { t } = useTranslation("index", { keyPrefix: "Events" });
    return (
        <section className={(className ? className + " " : "") + "events"}>
            <Container fluid="xl">
                <Row>
                    <Col className="" xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="max-events" >
                            <h6 className="header-square blue md-large lg-large">{t("Events")}</h6>
                            <Col className="event-collection" md={12} sm={12} xs={12} >
                                <Col className="event-item " md={5} sm={12} xs={12}>
                                    <Col className="event-img" md={12} sm={12} xs={12}>
                                        <div className="eventLatina"></div>
                                    </Col>
                                    <Col className="event-detail" md={12} sm={12} xs={12}>
                                        <Col className="event-title" md={12} sm={12} xs={12}>
                                            {t("Latina STEM leaders spotlight")}
                                        </Col>
                                        <Col className="event-text" md={12} sm={12} xs={12}>
                                            {t("In the first installment of our new series profiling Latinas taking the lead in the STEM sector, LABS talks with Rosalba Reynoso, the co-founder and CEO of Blue Trail Software.")}
                                        </Col>
                                        <Col className="event-button" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Button title={t("Read More Rosalba Paper")} href={withPrefix("Rosalba Reynoso interview.pdf")} target="_blank" rel="noreferrer" variant="success">{t("Read more")} 	<span>&#10140;</span></Button>
                                        </Col>
                                    </Col>
                                </Col>
                                <Col className="event-item" md={5} sm={12} xs={12}>
                                    <Col className="event-img" md={12} sm={12} xs={12}>
                                        <div className="eventLeadership"></div>
                                    </Col>
                                    <Col className="event-detail" md={12} sm={12} xs={12}>
                                        <Col className="event-title" md={12} sm={12} xs={12}>
                                            {t("Leadership in The Digital Age")}
                                        </Col>
                                        <Col className="event-text" md={12} sm={12} xs={12}>
                                            {t("The digital age enables novel organizational models, where power is distributed across the organization, in other words where each employee is entrusted with a certain level of decision power.")}
                                        </Col>
                                        <Col className="event-button" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Button href={paper} target="_blank" title={t("Download Remi's Paper")} variant="success">{t("Download")} 	<span>&#10140;</span></Button>
                                        </Col>
                                    </Col>
                                </Col>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/*  <Container fluid="xl">
            <Row>
                <Col className="" xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="max-events" >

                        <h4 className="header-square blue md-large lg-large">Events</h4>
                        <Col className="event-collection" md={12} sm={12} xs={12} >
                            <Col className="event-item " md={12} sm={12} xs={12} >
                                <Col className="event-img" md={4} sm={12} xs={12}  >
                                    <div></div>
                                </Col>
                                <Col className="event-detail" md={8} sm={12} xs={12} >
                                    <Col className="event-date" md={12} sm={12} xs={12}>
                                        12 / 12 / 2022
                                    </Col>
                                    <Col className="event-title" md={12} sm={12} xs={12}>
                                        Latina STEM leaders spotlight:
                                        Software trailblazer Rosalba Reynoso.
                                    </Col>
                                    <Col className="event-text" md={12} sm={12} xs={12}>
                                        In the first installment of our new series profiling Latinas taking the
                                        lead in the STEM sector, LABS talks with Rosalba Reynoso, the co-founder and
                                        CEO of Blue Trail Software. Her story is as unconventional as it is inspiring.
                                    </Col>
                                </Col>

                            </Col>


                            <Col className="event-item " md={12} sm={12} xs={12}>
                                <Col className="event-img" md={4} sm={12} xs={12}>
                                    <div></div>
                                </Col>
                                <Col className="event-detail" md={8} sm={12} xs={12}>
                                    <Col className="event-date" md={12} sm={12} xs={12}>
                                        12 / 12 / 2022
                                    </Col>
                                    <Col className="event-title" md={12} sm={12} xs={12}>
                                        Latina STEM leaders spotlight:
                                        Software trailblazer Rosalba Reynoso.
                                    </Col>
                                    <Col className="event-text" md={12} sm={12} xs={12}>
                                        In the first installment of our new series profiling Latinas taking the
                                        lead in the STEM sector, LABS talks with Rosalba Reynoso, the co-founder and
                                        CEO of Blue Trail Software. Her story is as unconventional as it is inspiring.
                                    </Col>
                                </Col>

                            </Col>


                            <Col className="event-item" md={12} sm={12} xs={12}>
                                <Col className="event-img" md={4} sm={12} xs={12}>
                                    <div></div>
                                </Col>
                                <Col className="event-detail" md={8} sm={12} xs={12}>
                                    <Col className="event-date" md={12} sm={12} xs={12}>
                                        12 / 12 / 2022
                                    </Col>
                                    <Col className="event-title" md={12} sm={12} xs={12}>
                                        Latina STEM leaders spotlight:
                                        Software trailblazer Rosalba Reynoso.
                                    </Col>
                                    <Col className="event-text" md={12} sm={12} xs={12}>
                                        In the first installment of our new series profiling Latinas taking the
                                        lead in the STEM sector, LABS talks with Rosalba Reynoso, the co-founder and
                                        CEO of Blue Trail Software. Her story is as unconventional as it is inspiring.
                                    </Col>
                                </Col>

                            </Col>




                        </Col>


                    </div>

                </Col>


            </Row>
</Container>*/}

        </section >
    )
}

export default Events
