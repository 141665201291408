import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import videoPresentationEN from "../../images/home/CultureVideo.mp4"
import videoPresentationES from "../../images/home/CultureVideoES.mp4"
import video from "../../images/home/videoThumbnail.mp4"
import "./presentationVideo.scss"

const VideoPresentation = () => {
  const [show, setShow] = useState(false)
  const { t, i18n } = useTranslation("index", {
    keyPrefix: "VideoPresentation",
  })
  const context = React.useContext(t)
  const videoPresentation = {
    en: videoPresentationEN,
    es: videoPresentationES,
  }
  const modalStyle = {
    backgroundColor: "black",
    padding: "0",
  }

  return (
    <section className="videoPresentation">
      <Row>
        <Col className="videoSection">
          <div className="vid_1_wrapper">
            <video muted autoPlay loop playsInline className="vid_1">
              <source src={video} type="video/mp4" />
            </video>
            {/* <div className="vid_1_text">
                            <i className="bi bi-play-fill"/> <br></br>
                                Play Video
                        </div> */}
          </div>
        </Col>
        <Col className="buttonSection">
          <Col className="img" md={12} lg={12}></Col>
          <Col className="text" xs={10} sm={10} md={12} lg={12}>
            <h1>
              {t("Watch our 2-minute")} <br></br>
              {t("company presentation")}{" "}
            </h1>
            <Button
              className="playVideoButton"
              variant="success"
              onClick={() => setShow(true)}
            >
              <i className="bi bi-play-fill" />
              {t("Let's Start")}
            </Button>
          </Col>
        </Col>
      </Row>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={modalStyle}>
          <video
            width="100%"
            height="100%"
            className="videoYoutube"
            controls
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          >
            <source src={videoPresentation[i18n.language]} type="video/mp4" />
            {t("Your browser does not support the video tag.")}
          </video>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default VideoPresentation
